import React, { Component } from 'react'
import { jwtDecode } from 'jwt-decode'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faTag, faBoltLightning } from '@fortawesome/free-solid-svg-icons'

import Navbar from '../../components/navbar/navbar'
import Footer from '../../components/footer/footer'

import Spinner from '../../components/spinner'

import './find.scss'

export class FindLawyers extends Component {
  constructor() {
    super()

    this.state = {
      loading: true,
      user: jwtDecode(localStorage.getItem('token')),
      lawyer: ''
    }
  }

  async componentDidMount() {
    if (this.state.user.package === 'Free') return window.location.href = '/packages'

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }

      let { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/lawyer/`, config)

      if (data.success) this.setState({ loading: false, lawyer: data.lawyer })
      console.log(this.state.lawyer)
    } catch (error) {
      this.setState({ loading: false })
      console.log(error)
    }
  }

  render() {
    return (
      <div className='lawyers'>
        <Navbar></Navbar>
        <div className="container my-5">
          <h1>Lawyers</h1>
          <p>Browse Lawyers</p>

          <div className="lawyers-list">
            <div className="row mt-5">
              <div className="col-md-3">
                <h3 className='mb-4'>Filters</h3>
                <input type="text" className='form-control mb-3' placeholder='Search Lawyers' />
                <input type="text" className='form-control mb-3' placeholder='Location' />
                <input type="text" className='form-control mb-3' placeholder='Categories' />
                <div className="d-grid">
                  <button className="button">Search</button>
                </div>
              </div>
              <div className="col-md-9">
                {(this.state.loading) ? <Spinner></Spinner> : (
                  this.state.lawyer.map((profile, index) => (
                    <div className="lawyer-profile mt-3" onClick={() => window.location.href = `/lawyer/${profile._id}`}>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="image">
                            <img src={profile.picture} alt={profile.name} className='img-fluid'/>
                            <div className="red">{profile.category}</div>
                          </div>
                        </div>
                        <div className="col-md-9 d-flex align-items-center">
                          <div className='d-'>
                            <h3 className='d-flex align-items-start'>{profile.name}<span className='verified ms-2'>{profile.verified ? <FontAwesomeIcon icon={faCircleCheck} size='2xs'></FontAwesomeIcon> : null}</span></h3>
                            <p>{profile.location.address}</p>
                              <div className='d-flex align-items-center'>
                                {profile.pricing ? (
                                  <div className="d-flex align-items-center lawyer-info">
                                    <div className="pricing"><FontAwesomeIcon icon={faTag} size='1x'></FontAwesomeIcon></div>
                                    <span className='ms-2'>&#2547; {profile.pricing[0]} - &#2547; {profile.pricing[1]}</span>
                                  </div>
                                ) : null}
                                {profile.booking ? (
                                  <div className="d-flex align-items-center lawyer-info">
                                    <div className="booking"><FontAwesomeIcon icon={faBoltLightning} size='1x'></FontAwesomeIcon></div>
                                    <span className='ms-2'>Instant Booking</span>
                                  </div>
                                ) : null}
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default FindLawyers