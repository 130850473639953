import { useState } from 'react'
import { Link } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faPaste, faEnvelopeOpen, faStar, faHeart, faUser, faPowerOff, faBars, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { Sidebar, Menu, MenuItem, sidebarClasses } from 'react-pro-sidebar'

import Logo from '../../assets/footer-logo.png'

const DashSidebar = () => {
  const [sidebarCollapse, setSidebarCollapse] = useState(false)

  return (
    <Sidebar collapsed={sidebarCollapse} toggled={true} rootStyles={{ [`.${sidebarClasses.container}`]: { backgroundColor: "#323658", color: 'white' } }}>
      <Menu menuItemStyles={{ button: { [`&:hover`]: { backgroundColor: "#f1295a", borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px' } } }}>
        <MenuItem className="menu1" icon={<FontAwesomeIcon icon={faBars}></FontAwesomeIcon>} onClick={() => setSidebarCollapse(!sidebarCollapse)}>
          <div className="branding">
            <img src={Logo} alt="logo" width='100px'/>
          </div>
        </MenuItem>

        <MenuItem component={<Link to='/dashboard'></Link>} icon={<FontAwesomeIcon icon={faGear}/>}>Dashboard</MenuItem>
        <MenuItem component={<Link to='/dashboard/my-bookings'></Link>} icon={<FontAwesomeIcon icon={faPaste}/>}>My Bookings</MenuItem>
        <MenuItem component={<Link to='/dashboard/messages'></Link>} icon={<FontAwesomeIcon icon={faEnvelopeOpen}/>}>Messages</MenuItem>
        {(jwtDecode(localStorage.getItem('token')).type === 'lawyer') ? <MenuItem component={<Link to='/dashboard/lawyer-profile'></Link>} icon={<FontAwesomeIcon icon={faLayerGroup}/>}>Lawyer Profile</MenuItem> : null}
        <MenuItem component={<Link to='/dashboard/reviews'></Link>} icon={<FontAwesomeIcon icon={faStar}/>}>Reviews</MenuItem>
        <MenuItem component={<Link to='/dashboard/bookmarks'></Link>} icon={<FontAwesomeIcon icon={faHeart}/>}>Bookmarks</MenuItem>

        <MenuItem component={<Link to='/dashboard/my-account'></Link>} icon={<FontAwesomeIcon icon={faUser}/>}>My Account</MenuItem>
        <MenuItem component={<Link to='/logout'></Link>} icon={<FontAwesomeIcon icon={faPowerOff}/>}>Logout</MenuItem>
      </Menu>
    </Sidebar>
  )
}

export default DashSidebar