import React, { Component } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { withRouter } from "../../controllers/withRouter";

import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import Error from "../../components/error/error";
import Spinner from "../../components/spinner";

import signup from "../../assets/signup.jpg";

import "./signup.scss";

export class ResetPassword extends Component {
  constructor() {
    super();

    if (localStorage.getItem("token")) window.location.href = "/dashboard";

    this.state = {
      password: "",
      confirmPassword: "",
      passwordType: true,
      confirmPasswordType: true,
      error: null,
      passwordLength: false,
      passwordHasUppercase: false,
      passwordHasDigit: false,
      passwordHasSpecialChar: false,
      passwordFocus: false,
    };

    this.setNewPassword = this.setNewPassword.bind(this);
  }

  passwordCheck(password) {
    this.setState({ passwordLength: password.length >= 8 ? true : false });
    this.setState({ passwordHasUppercase: /[A-Z]+/.test(password) });
    this.setState({ passwordHasDigit: /[0-9]+/.test(password) });
    this.setState({ passwordHasSpecialChar: /[^A-Za-z0-9]+/.test(password) });
    this.setState({ password: password });
  }

  async setNewPassword(event) {
    event.preventDefault();

    this.setState({ error: null });
    if (this.state.password !== this.state.confirmPassword)
      return this.setState({
        error: "Password doesn't match. Please check again!",
      });

    try {
      let resetToken = this.props.params.token;

      let { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/reset-password/${resetToken}`,
        {
          password: this.state.password,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      if (data.success) {
        alert(
          "Password has been reset successfully. Redirecting you to login page..."
        );
        window.location.href = "/sign-up?method=login";
      }
    } catch (error) {
      this.setState({
        error: "Password reset link has been expired. Please try again!",
      });
      console.error(error);
    }
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div className="signup my-5">
          <div className="container">
            {this.state.loading ? (
              <Spinner></Spinner>
            ) : (
              <div className="row d-flex align-items-center">
                <div className="col-md-6">
                  <img src={signup} alt="signup" className="img-fluid" />
                </div>

                <div className="col-md-6 mt-3">
                  {this.state.error ? (
                    <Error message={this.state.error}></Error>
                  ) : null}

                  <h4>Reset password</h4>
                  <p>Create a strong password and store it on a safe place.</p>

                  <form onSubmit={this.setNewPassword}>
                    <div className="d-flex align-items-center">
                      <input
                        type={this.state.passwordType ? "password" : "text"}
                        className="form-control mb-3 loginPassword"
                        required
                        value={this.state.password}
                        onChange={(event) =>
                          this.passwordCheck(event.target.value)
                        }
                        onFocus={() => this.setState({ passwordFocus: true })}
                        onBlur={() => this.setState({ passwordFocus: false })}
                        placeholder="Password"
                      />
                      {this.state.passwordType ? (
                        <span
                          onClick={() => this.setState({ passwordType: false })}
                        >
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            size="1x"
                            className="eyes"
                          ></FontAwesomeIcon>
                        </span>
                      ) : (
                        <span
                          onClick={() => this.setState({ passwordType: true })}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            size="1x"
                            className="eyes"
                          ></FontAwesomeIcon>
                        </span>
                      )}
                    </div>

                    <div className="d-flex align-items-center">
                      <input
                        type={
                          this.state.confirmPasswordType ? "password" : "text"
                        }
                        className="form-control mb-3 loginPassword"
                        required
                        value={this.state.confirmPassword}
                        onChange={(event) =>
                          this.setState({ confirmPassword: event.target.value })
                        }
                        placeholder="Confirm password"
                      />
                      {this.state.confirmPasswordType ? (
                        <span
                          onClick={() =>
                            this.setState({ confirmPasswordType: false })
                          }
                        >
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            size="1x"
                            className="eyes"
                          ></FontAwesomeIcon>
                        </span>
                      ) : (
                        <span
                          onClick={() =>
                            this.setState({ confirmPasswordType: true })
                          }
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            size="1x"
                            className="eyes"
                          ></FontAwesomeIcon>
                        </span>
                      )}
                    </div>

                    {this.state.passwordFocus ? (
                      <div className="passVal">
                        <div
                          className={
                            this.state.passwordLength &&
                            this.state.passwordHasDigit &&
                            this.state.passwordHasUppercase &&
                            this.state.passwordHasSpecialChar
                              ? "passsuccess"
                              : "passerror"
                          }
                        >
                          <h5 className="mb-3">Password should include: </h5>
                          <div className="row">
                            <div className="col-md-6">
                              <p>
                                {this.state.passwordLength ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    &#10003; At least 8 characters
                                  </span>
                                ) : (
                                  <span style={{ color: "var(--primary-red)" }}>
                                    &#10005; At least 8 characters
                                  </span>
                                )}{" "}
                              </p>
                              <p>
                                {this.state.passwordHasUppercase ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    &#10003; An uppercase character
                                  </span>
                                ) : (
                                  <span style={{ color: "var(--primary-red)" }}>
                                    &#10005; An uppercase character
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p>
                                {this.state.passwordHasDigit ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    &#10003; A digit (number)
                                  </span>
                                ) : (
                                  <span style={{ color: "var(--primary-red)" }}>
                                    &#10005; A digit (number)
                                  </span>
                                )}
                              </p>
                              <p>
                                {this.state.passwordHasSpecialChar ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    &#10003; A special characte
                                  </span>
                                ) : (
                                  <span style={{ color: "var(--primary-red)" }}>
                                    &#10005; A special characte
                                  </span>
                                )}
                                r
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <button className="button" type="submit">
                      Reset password
                    </button>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
