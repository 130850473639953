import React, { Component } from 'react'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import dateFormat from 'dateformat'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faCircleCheck } from '@fortawesome/free-regular-svg-icons'

import DashSidebar from '../../../components/dashboard/sidebar'
import Spinner from '../../../components/spinner'

import './bookings.scss'

export class MyBookings extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      user: jwtDecode(localStorage.getItem('token')),
      bookings: []
    }

    document.title = 'My Bookings - LegalX'
  }

  async componentDidMount() {
    if (this.state.user.package === 'Free') return window.location.href = '/packages'

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }

      let { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/lawyer/get-booking`, config)

      if (data.success) {
        this.setState({ bookings: data.data, loading: false })
      }
    } catch (error) {
      this.setState({ loading: false })
      console.log(error)
    }
  }


  render() {
    return (
      <div className='dashboard'>
        <div className="sidebar">
          <DashSidebar></DashSidebar>
        </div>
        <div className="contents">
          <h1 className='my-4'>My Bookings</h1>

          {(this.state.loading) ? <Spinner></Spinner> : (
            <div className='booking my-3'>
              <div className='d-flex justify-content-between align-items-center heading'>
                <h4>Booking Requests</h4>
                <select name="" className='form-control w-25' id="">
                  <option value="All">All</option>
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
              <div className="bookings">
                {this.state.bookings.map((booking, index) => (
                  this.state.user.type === 'user' ? <Bookings id={booking.id} picture={booking.lawyerPicture} name={booking.lawyerName} date={booking.date} time={booking.time} phoneNumber={booking.lawyerNumber} email={booking.lawyerEmail} message={booking.message} timestamps={booking.timestamp} type={this.state.user.type} status={booking.status} key={index}></Bookings> : <Bookings id={booking.id} picture={booking.userPicture} name={booking.userName} date={booking.date} time={booking.time} phoneNumber={booking.userNumber} email={booking.userEmail} message={booking.message} timestamps={booking.timestamp} type={this.state.user.type} status={booking.status} key={index}></Bookings>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

class Bookings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      status: this.props.status
    }
  }

  async updateStatus(id, status) {
    this.setState({ loading: true })

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }

      let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/lawyer/update-booking`, { id, status }, config)

      if (data.success) this.setState({ loading: false, status: data.status })
    } catch (error) {
      this.setState({ loading: false })
      console.error(error)
    }
  }


  render() {
    return (
      <div className='booking-items my-3 px-4 py-5'>
        {(this.state.loading) ? <Spinner></Spinner> : (
          <div className="row">
            <div className="col-md-9">
              <div className="d-flex align-items-start gap-4">
                <div className="avatar">
                  <img src={this.props.picture} alt={this.props.name} width='80px' className='rounded-circle' />
                </div>
                <div className="booking-info mt-1">
                  <h3 className='d-flex align-items-center gap-2'>{this.props.name} <span className={'status ' + (this.state.status === 'Pending' ? 'pending' : this.state.status === 'Approved' ? 'approved' : this.state.status === 'Rejected' ? 'rejected' : '')}>{this.state.status}</span></h3>
                  <div className="my-3">
                    <p><span className='bold'>Booking Date:</span> <span className='date'>{dateFormat(this.props.date, 'fullDate')} at {this.props.time} GMT +6</span></p>
                    <p><span className='bold'>{this.props.type === 'Lawyer' ? 'Client' : 'Lawyer'}:</span> <span>{this.props.name} | {this.props.email} | {this.props.phoneNumber}</span></p>
                    <p><span className='bold'>Message:</span> <span>{this.props.message}</span></p>
                    <p><span className='bold'>Request Sent:</span> <span className='date'>{dateFormat(this.props.timestamps)}</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              {(this.props.type === 'lawyer') ? (
                <div className="d-flex align-items-center justify-content-center gap-3 h-100">
                  {(this.state.status !== 'Rejected') ? <button className="rejectButton" onClick={() => this.updateStatus(this.props.id, 'Rejected')}><FontAwesomeIcon icon={faCircleXmark}></FontAwesomeIcon> Reject</button> : null}
                  {(this.state.status !== 'Approved' && this.state.status !== 'Rejected') ? <button className="approveButton" onClick={() => this.updateStatus(this.props.id, 'Approved')}><FontAwesomeIcon icon={faCircleCheck}></FontAwesomeIcon> Approve</button> : null}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default MyBookings