import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf, faDesktop, faRocket } from '@fortawesome/free-solid-svg-icons'

import Navbar from '../../components/navbar/navbar'
import Footer from '../../components/footer/footer'

import './home.scss'

export class Package extends Component {
  constructor() {
    super()

    document.title = 'Packages - LegalX'
  }

  async orderPackage(type, price) {
    if (!localStorage.getItem('token')) return window.location.href = '/sign-up?method=register'
    localStorage.setItem('cart', JSON.stringify({ type, price }))
    return window.location.href = '/checkout'
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <section className="plans">
          <div className="container">
            <h1>Select your plan</h1>
            <div className="row">
              <div className="col-md-4">
                <div className="plan-card">
                  <h4><FontAwesomeIcon style={{ color: '#00C7E5' }} icon={faLeaf}></FontAwesomeIcon> Daily</h4>
                  <p>Get the support for 24 hours</p>
                  <div className="text-center mb-4">
                    <p className="currency">100 <span>BDT</span></p>
                    <button className="plan-button" onClick={() => this.orderPackage('Daily', 100)}>Get Started</button>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="plan-card bgblue">
                  <h4><FontAwesomeIcon style={{ color: '#00C7E5' }} icon={faDesktop}></FontAwesomeIcon> Weekly</h4>
                  <p>Get the support for 7 days</p>
                  <div className="text-center mb-4">
                    <p className="currency">600 <span>BDT</span></p>
                    <button className="plan-button" onClick={() => this.orderPackage('Weekly', 600)}>Get Started</button>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="plan-card">
                  <h4><FontAwesomeIcon style={{ color: '#00C7E5' }} icon={faRocket}></FontAwesomeIcon> Monthly</h4>
                  <p>Get the support for 30 days</p>
                  <div className="text-center mb-4">
                    <p className="currency">2000 <span>BDT</span></p>
                    <button className="plan-button" onClick={() => this.orderPackage('Monthly', 2000)}>Get Started</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </div>
    )
  }
}

export default Package