import React, { Component } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import { withRouter } from '../../controllers/withRouter'

import signup from '../../assets/signup.jpg'

import Navbar from '../../components/navbar/navbar'
import Footer from '../../components/footer/footer'

import Error from '../../components/error/error'
import Spinner from '../../components/spinner'

import './signup.scss'

export class Signup extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      registerError: null,
      loginError: null,
      method: Object.fromEntries(new URLSearchParams(window.location.search)).method || 'login',
      regType: 'user',
      loginEmail: '',
      loginPasswordType: true,
      regPasswordType: true,
      regCPasswordType: true,
      loginPassword: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      regPassword: '',
      regCPassword: '',
      passwordLength: false,
      passwordHasUppercase: false,
      passwordHasDigit: false,
      passwordHasSpecialChar: false,
      promotionEmails: false,
      passwordFocus: false,
    }

    this.login = this.login.bind(this)
  }

  passwordCheck(password) {
    this.setState({ passwordLength: password.length >= 8 ? true : false }) 
    this.setState({ passwordHasUppercase: /[A-Z]+/.test(password) }) 
    this.setState({ passwordHasDigit: /[0-9]+/.test(password) }) 
    this.setState({ passwordHasSpecialChar: /[^A-Za-z0-9]+/.test(password) })
    this.setState({ regPassword: password })
  }

  async login(event) {
    event.preventDefault()

    this.setState({ loginError: null, loading: true })

    try {
      let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login`, {
        email: this.state.loginEmail,
        password: this.state.loginPassword
      })

      if (data.success) {
        localStorage.setItem('token', data.token)
        window.location.href = '/dashboard'
      } else {
        return this.setState({ loginError: 'Incorrect email or password. Please check again!', loading: false })
      }
    } catch (error) {
      return this.setState({ loginError: 'Incorrect email or password. Please check again!', loading: false })
    }
  }

  async register(event) {
    event.preventDefault()

    this.setState({ registerError: null, loading: true })

    if (!this.state.passwordLength || !this.state.passwordHasDigit || !this.state.passwordHasUppercase || !this.state.passwordHasSpecialChar) return this.setState({ registerError: 'Please create a stronger password!' })
    if (this.state.regPassword !== this.state.regCPassword) return this.setState({ registerError: "Password doesn't match. Please check again!" })

    try {
      let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/register`, {
        userType: this.state.regType,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phoneNumber: this.state.phoneNumber,
        email: this.state.email,
        password: this.state.regPassword,
        promotionalEmails: this.state.promotionEmails
      })

      if (data.success) {
        localStorage.setItem('token', data.token)
        window.location.href = '/dashboard'
      } else if (data.code === 1) {
        return this.setState({ registerError: 'Account already exist. Please try to login!', loading: false })
      }
    } catch (error) {
      console.error(error)
      return this.setState({ registerError: error.response.data.error, loading: false })
    }
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div className='signup my-5'>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img src={signup} alt="signup" className='img-fluid' />
              </div>
              <div className="col-md-6">
                {(this.state.loading) ? (
                  <Spinner></Spinner> 
                ) : (
                  <div>
                    <ul className="nav nav-tabs">
                      <li className='nav-item'>
                        <a className={'nav-link' + (this.state.method === 'login' ? ' active' : '')} href='/sign-up?method=login'>Log In</a>
                      </li>
                      <li className='nav-item'>
                        <a className={'nav-link' + (this.state.method === 'register' ? ' active' : '')} href='/sign-up?method=register'>Register</a>
                      </li>
                    </ul>
    
                    {(this.state.method === 'login') ? (
                      <div className="login">
                        {(this.state.loginError) ? <Error message={this.state.loginError}></Error> : null}

                        <h4>Login to your account</h4>
                        <p>Thank you for getting back, access legal assitance according to your need.</p>
    
                        <form onSubmit={this.login}>
                          <input className='form-control mb-3' type='email' required onChange={(event) => this.setState({ loginEmail: event.target.value })} placeholder='Email address'></input>
    
                          <div className="d-flex align-items-center">
                            <input type={this.state.loginPasswordType ? 'password' : 'text'} className='form-control mb-3 loginPassword' required value={this.state.password} onChange={(event) => this.setState({ loginPassword: event.target.value })} placeholder='Password'/>
                            {this.state.loginPasswordType ? (
                              <span onClick={() => this.setState({ loginPasswordType: false })}><FontAwesomeIcon icon={faEyeSlash} size='1x' className='eyes'></FontAwesomeIcon></span>
                            ) : (
                              <span onClick={() => this.setState({ loginPasswordType: true })}><FontAwesomeIcon icon={faEye} size='1x' className='eyes'></FontAwesomeIcon></span>
                            )}
                          </div>
    
                          <p><a href="/forgot-password">Forgot password?</a></p>
    
                          <button className="button" type='submit'>Login</button>
                          
                          <p>Don't have an account yet? <a href="/" className='join'>Join LegalX</a></p>
                        </form>
                      </div>
                    ) : (
                      <div className="register">
                        {this.state.registerError ? <Error message={this.state.registerError}></Error> : null}
                        <form onSubmit={(event) => this.register(event)}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="d-grid">
                                <button className={'reg button ' + (this.state.regType === 'user' ? 'regactive' : '')} onClick={(event) => {
                                  event.preventDefault()
                                  this.setState({ regType: 'user' })}
                                }>LegalX User</button>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="d-grid">
                                <button className={'reg button ' + (this.state.regType === 'lawyer' ? 'regactive' : '')} onClick={(event) => {
                                  event.preventDefault()
                                  this.setState({ regType: 'lawyer' })}
                                }>Lawyer</button>
                              </div>
                            </div>
                          </div>
    
                          <div className="row my-3">
                            <div className="col-md-6">
                              <input className='form-control mb-3' type='text' required onChange={(event) => this.setState({ firstName: event.target.value })} placeholder='First Name'></input>
                            </div>
                            <div className="col-md-6">
                              <input className='form-control mb-3' type='text' required onChange={(event) => this.setState({ lastName: event.target.value })} placeholder='Last Name'></input>
                            </div>
                            <div className="col-md-6">
                              <input className='form-control mb-3' type='text' required onChange={(event) => this.setState({ phoneNumber: event.target.value })} placeholder='Phone Number'></input>
                            </div>
                            <div className="col-md-6">
                              <input className='form-control mb-3' type='email' required onChange={(event) => this.setState({ email: event.target.value })} placeholder='Email Address'></input>
                            </div>
                            <div className="col-md-6">
                              <div className="d-flex align-items-center">
                                <input type={this.state.regPasswordType ? 'password' : 'text'} className='form-control mb-3 loginPassword' required value={this.state.password} onChange={(event) => this.passwordCheck(event.target.value)} onFocus={() => this.setState({ passwordFocus: true })} onBlur={() => this.setState({ passwordFocus: false })} placeholder='Password'/>
                                {this.state.regPasswordType ? (
                                  <span onClick={() => this.setState({ regPasswordType: false })}><FontAwesomeIcon icon={faEyeSlash} size='1x' className='eyes'></FontAwesomeIcon></span>
                                ) : (
                                  <span onClick={() => this.setState({ regPasswordType: true })}><FontAwesomeIcon icon={faEye} size='1x' className='eyes'></FontAwesomeIcon></span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="d-flex align-items-center">
                                <input type={this.state.regCPasswordType ? 'password' : 'text'} className='form-control mb-3 loginPassword' required value={this.state.password} onChange={(event) => this.setState({ regCPassword: event.target.value })} placeholder='Confirm Password'/>
                                {this.state.regCPasswordType ? (
                                  <span onClick={() => this.setState({ regCPasswordType: false })}><FontAwesomeIcon icon={faEyeSlash} size='1x' className='eyes'></FontAwesomeIcon></span>
                                ) : (
                                  <span onClick={() => this.setState({ regCPasswordType: true })}><FontAwesomeIcon icon={faEye} size='1x' className='eyes'></FontAwesomeIcon></span>
                                )}
                              </div>
                            </div>
    
                            {(this.state.passwordFocus) ? (
                              <div className="col-12">
                                <div className='passVal'>
                                  <div className={this.state.passwordLength && this.state.passwordHasDigit && this.state.passwordHasUppercase && this.state.passwordHasSpecialChar ? 'passsuccess' : 'passerror'}>
                                    <h5 className='mb-3'>Password should include: </h5>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <p>{this.state.passwordLength ? <span style={{ color: 'green', fontWeight: 'bold' }}>&#10003; At least 8 characters</span> : <span style={{ color: 'var(--primary-red)' }}>&#10005; At least 8 characters</span>} </p>
                                        <p>{this.state.passwordHasUppercase ? <span style={{ color: 'green', fontWeight: 'bold' }}>&#10003; An uppercase character</span> : <span style={{ color: 'var(--primary-red)' }}>&#10005; An uppercase character</span>}</p>
                                      </div>
                                      <div className="col-md-6">
                                        <p>{this.state.passwordHasDigit ? <span style={{ color: 'green', fontWeight: 'bold' }}>&#10003; A digit (number)</span> : <span style={{ color: 'var(--primary-red)' }}>&#10005; A digit (number)</span>}</p>
                                        <p>{this.state.passwordHasSpecialChar ? <span style={{ color: 'green', fontWeight: 'bold' }}>&#10003; A special characte</span> : <span style={{ color: 'var(--primary-red)' }}>&#10005; A special characte</span>}r</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
    
                          <div className="form-check mt-2">
                            <input className="form-check-input" type="checkbox" id="emails" onChange={() => this.setState({ promotionEmails: !this.state.promotionEmails })} />
                            <label className="form-check-label">Yes, I want to receive LegalX emails</label>
                          </div>
    
                          <div className="form-check mt-2">
                            <input className="form-check-input" type="checkbox" required id="privacy" />
                            <label className="form-check-label">I agree to the <a href='/privacy-policy' className='join'>Privacy Policy & Terms and Conditions</a></label>
                          </div>
    
                          <button className="button">Register</button>
                        </form>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default withRouter(Signup)