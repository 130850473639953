import React, { Component } from 'react'
import { jwtDecode } from 'jwt-decode'

import Navbar from '../../../components/navbar/navbar'
import Footer from '../../../components/footer/footer'

import harrasment from '../../../assets/harrasment.webp'

import './harrasment.scss'

export class Harrasement extends Component {
  constructor() {
    super()

    this.state = {
      user: jwtDecode(localStorage.getItem('token'))
    }
  }
  
  async componentDidMount() {
    if (this.state.user.package === 'Free') return window.location.href = '/packages' 
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div className='harrasement'>
          <div className="container">
            <h1>Rape/ Harrasment/<br></br>Abuse Queries</h1>

            <div className="text-center">
              <img src={harrasment} alt="rights" className='img-fluid' />
            </div>

            <p>Gender-based violence against women in Bangladesh has increased by 70% during the Covid-19 pandemic and, we think it is time to create a safe space for victims of abuse/harassment/rape.</p>
            <p>Suppose you are a survivor of sexual harassment, sexual assault, rape, or other sexual violence.</p>
            <p>In that case, the LegalX team is here to provide full support so you can learn your legal options with our team, comprising of female members only, without any judgment. You can avail of this option 24/7 so you can connect with a lawyer in the quickest time possible, with all possible resources at your disposal. All the information you share here is protected and confidential.</p>

            <div className="text-center pt-5">
              <button className="button-outline" onClick={() => window.location.href = '/dashboard/queries'}>Create a query</button>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default Harrasement