import React, { Component } from 'react'
import { jwtDecode } from 'jwt-decode'

import Navbar from '../../../components/navbar/navbar'
import Footer from '../../../components/footer/footer'

import one from '../../../assets/library/1.webp'
import two from '../../../assets/library/2.webp'
import three from '../../../assets/library/3.webp'
import four from '../../../assets/library/4.webp'
import five from '../../../assets/library/5.webp'
import six from '../../../assets/library/6.webp'

export class Library extends Component {
  constructor() {
    super()

    this.state = {
      user: jwtDecode(localStorage.getItem('token'))
    }
  }
  
  async componentDidMount() {
    if (this.state.user.package === 'Free') return window.location.href = '/packages' 
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div className="library py-5 my-5">
          <div className="container">
            <div className="row gx-2 gy-3">
              <div className="col-md-4" style={{ cursor: 'pointer' }} onClick={() => window.location.href = '/dashboard/library/pdf?src=one'}>
                <img src={one} alt="library" className='img-fluid' />
              </div>
              <div className="col-md-4" style={{ cursor: 'pointer' }} onClick={() => window.location.href = '/dashboard/library/pdf?src=two'}>
                <img src={two} alt="library" className='img-fluid' />
              </div>
              <div className="col-md-4" style={{ cursor: 'pointer' }} onClick={() => window.location.href = '/dashboard/library/pdf?src=three'}>
                <img src={three} alt="library" className='img-fluid' />
              </div>
              <div className="col-md-4" style={{ cursor: 'pointer' }} onClick={() => window.location.href = '/dashboard/library/pdf?src=four'}>
                <img src={four} alt="library" className='img-fluid' />
              </div>
              <div className="col-md-4" style={{ cursor: 'pointer' }} onClick={() => window.location.href = '/dashboard/library/pdf?src=five'}>
                <img src={five} alt="library" className='img-fluid' />
              </div>
              <div className="col-md-4" style={{ cursor: 'pointer' }} onClick={() => window.location.href = '/dashboard/library/pdf?src=six'}>
                <img src={six} alt="library" className='img-fluid' />
              </div>
              
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default Library