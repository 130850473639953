import React, { Component } from 'react'
import { jwtDecode } from 'jwt-decode'

import Navbar from '../../../components/navbar/navbar'
import Footer from '../../../components/footer/footer'

import './rights.scss'

export class Rights extends Component {
  constructor() {
    super()

    this.state = {
      user: jwtDecode(localStorage.getItem('token')),
    }
  }

  async componentDidMount() {
    if (this.state.user.package === 'Free') return window.location.href = '/packages'
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div className="rights">
          <div className="landing">
          </div>
          <div className="container my-5">
            <div className="row">
              <div className="col-md-6">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/0lRAqE3Or_o?si=xnsviIdZZ47MCxn2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
              <div className="col-md-6">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/JH4Rkjhaz_w?si=WxorikCgbD3rcymT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default Rights