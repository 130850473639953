import { Component } from "react";
import './legalservices.css';

export default class LegalServices extends Component {
  render() {
    return (
      <div className="legalServices">
        <div className="bar" style={{backgroundColor: this.props.color, height: '30px', borderTopLeftRadius: '5px', borderTopRightRadius: '10px'}}></div>
        <div className="content">
          <h3>{this.props.title}</h3>
          <p>{this.props.desc}</p>
        </div>
      </div>
    )
  }
};