import React, { Component } from 'react'

import Navbar from '../../components/navbar/navbar'
import Footer from '../../components/footer/footer'

import image1 from '../../assets/about-us1.webp'
import image2 from '../../assets/about-us2.webp'

import one from '../../assets/team/1.webp'
import two from '../../assets/team/2.webp'
import three from '../../assets/team/3.webp'
import four from '../../assets/team/4.webp'
import five from '../../assets/team/5.webp'
import six from '../../assets/team/6.webp'
import seven from '../../assets/team/7.webp'
import eight from '../../assets/team/8.webp'
import nine from '../../assets/team/9.webp'
import ten from '../../assets/team/10.webp'

import './aboutus.scss'

export class AboutUs extends Component {
  constructor() {
    super()

    document.title = 'About Us - LegalX'
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div className="aboutus pt-5 mt-5">
          <div className="container top">
            <div className="row d-flex align-items-center">
              <h2 className="col-md-6">We build bridges between <span className='gray'>lay people and lawyers</span></h2>
              <p className="col-md-6">LegalX is an award-winning online legal service platform, dedicated to serving affordable and accessible legal solutions where people may ask legal questions and have them answered by legal professionals within 24 hours.</p>
            </div>
          </div>
          <div className="container-fluid py-5">
            <div className="row">
              <img src={image1} className="col-md-6 img-fluid" alt='image1' />
              <img src={image2} className="col-md-6 img-fluid" alt='image1'/>
            </div>
          </div>
          <div className="container mt-3 mb-5">
            <p>Every year, around 31 million people in Bangladesh face legal issues, and 15 million never even try to resolve them because people lack legal knowledge. There are issues with the accessibility to lawyers and the cost of hiring them. By providing individuals with convenient access to pertinent legal information when they need it, LegalX was created to empower Bangladeshi citizens and direct them toward justice.</p>
            <p>By making legal information accessible, we hope to foster an atmosphere where individuals are better informed about their legal rights and empowered to enforce them.</p>
          </div>

          <div className="team">
            <div className="container">
              <h1>Meet our<br></br>amazing team</h1>

              <div className="row">
                <div className="col-md-3">
                  <img src={one} alt="" className='img-fluid'/>
                  <div className="content">
                    <h5>Maliha Rahham</h5>
                    <p>Co-founder & CEO</p>
                  </div>
                </div>

                <div className="col-md-3">
                  <img src={two} alt="" className='img-fluid'/>
                  <div className="content">
                    <h5>Ziaul Hassan</h5>
                    <p>Advisor & Board Member</p>
                  </div>
                </div>

                <div className="col-md-3">
                  <img src={three} alt="" className='img-fluid'/>
                  <div className="content">
                    <h5>Syed Joheb Hassan</h5>
                    <p>Head of Operations</p>
                  </div>
                </div>

                <div className="col-md-3">
                  <img src={four} alt="" className='img-fluid'/>
                  <div className="content">
                    <h5>Muiz Shahabuddin</h5>
                    <p>Co-founder & CMO</p>
                  </div>
                </div>

                <div className="col-md-3">
                  <img src={five} alt="" className='img-fluid'/>
                  <div className="content">
                    <h5>Muneem Shahabuddin</h5>
                    <p>Board Member & CEO</p>
                  </div>
                </div>

                <div className="col-md-3">
                  <img src={six} alt="" className='img-fluid'/>
                  <div className="content">
                    <h5>Aminul Islam</h5>
                    <p>Software Developer</p>
                  </div>
                </div>

                <div className="col-md-3">
                  <img src={seven} alt="" className='img-fluid'/>
                  <div className="content">
                    <h5>Sadia Alam Amrin</h5>
                    <p>Senior Operations Executive</p>
                  </div>
                </div>

                <div className="col-md-3">
                  <img src={eight} alt="" className='img-fluid'/>
                  <div className="content">
                    <h5>Badiul Alam Fahad</h5>
                    <p>Senior Creative Executive</p>
                  </div>
                </div>

                <div className="col-md-3">
                  <img src={nine} alt="" className='img-fluid'/>
                  <div className="content">
                    <h5>Tayeba Akter</h5>
                    <p>Junior Creative Executive</p>
                  </div>
                </div>

                <div className="col-md-3">
                  <img src={ten} alt="" className='img-fluid'/>
                  <div className="content">
                    <h5>Dilip Chandra Das</h5>
                    <p>Office Manager</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default AboutUs