import React, { Component } from 'react'
import axios from 'axios'

import Navbar from '../../components/navbar/navbar'
import Footer from '../../components/footer/footer'
import Spinner from '../../components/spinner'

export class OrderComplete extends Component {
  constructor() {
    super()

    this.state = {
      loading: true,
      orderID: Object.fromEntries(new URLSearchParams(window.location.search)).order_id,
      token: localStorage.getItem('token')
    }
  }

  async componentDidMount() {
    try {
      console.log(this.state.token)

      let config = {
        headers: {
          authorization: `Bearer ${this.state.token}`
        }
      }
  
      let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/new-token`, {}, config)
  
      if (data.success) {
        localStorage.setItem('token', data.token)
        localStorage.removeItem('cart')
        this.setState({ loading: false })
      } 
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div className="container py-5">
          {(this.state.loading) ? <Spinner></Spinner> : (
            <div>
              <h1>Your order is completed!</h1>
              <h4>Order ID: {this.state.orderID}</h4>
              <button className='button' onClick={() => window.location.href = '/dashboard'}>Go to dashboard</button>
            </div>
          )}
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default OrderComplete