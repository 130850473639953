import React, { Component } from 'react'
import { SpinnerRoundFilled } from 'spinners-react'

export class Spinner extends Component {
  render() {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <SpinnerRoundFilled color='#f30606cb' size="120px"></SpinnerRoundFilled>
      </div>
    )
  }
}

export default Spinner