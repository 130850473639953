import React, { Component } from 'react'

export class Logout extends Component {
  constructor() {
    super()
    
    localStorage.removeItem('token')
    window.location.href = '/sign-up?method=login'
  }

  render() {
    return (
      <div>Logging out...</div>
    )
  }
}

export default Logout