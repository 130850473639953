import React, { Component } from 'react'

import { withRouter } from '../../../controllers/withRouter'

import one from '../../../assets/library/1.pdf'
import two from '../../../assets/library/2.pdf'
import three from '../../../assets/library/3.pdf'
import four from '../../../assets/library/4.pdf'
import five from '../../../assets/library/5.pdf'
import six from '../../../assets/library/6.pdf'
import { jwtDecode } from 'jwt-decode'

export class pdfViewer extends Component {
  constructor() {
    super()

    this.state = {
      user: jwtDecode(localStorage.getItem('token')),
      pdf: Object.fromEntries(new URLSearchParams(window.location.search)).src
    }
  }

  async componentDidMount() {
    if (this.state.user.package === 'Free') return window.location.href = '/packages'
  }

  render() {
    return (
      <div style={{ width: '100$', height: '100vh' }}>
        {this.state.pdf === 'one' ? <iframe src={one} width='100%' height='100%' title='one'></iframe> : this.state.pdf === 'two' ? <iframe src={two} width='100%' height='100%' title='two'></iframe> : this.state.pdf === 'three' ? <iframe src={three} width='100%' height='100%' title='three'></iframe> : this.state.pdf === 'four' ? <iframe src={four} width='100%' height='100%' title='four'></iframe> : this.state.pdf === 'five' ? <iframe src={five} width='100%' height='100%' title='five'></iframe> : this.state.pdf === 'six' ? <iframe src={six} width='100%' height='100%' title='six'></iframe> : null}
      </div>
    )
  }
}

export default withRouter(pdfViewer)