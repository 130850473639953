import React, { Component } from 'react'
import axios from 'axios'
import L from 'leaflet';
import { jwtDecode } from 'jwt-decode'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import ReactQuill from 'react-quill'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { TimePicker, DatePicker } from 'antd'
import { Markup } from 'interweave'

import { faClipboard, faImage, faPaste, faClock } from '@fortawesome/free-regular-svg-icons'
import { faLocationDot, faCalendarCheck, faTag, faPhone, faEnvelope, faPenToSquare, faCheck } from '@fortawesome/free-solid-svg-icons'

import DashSidebar from '../../components/dashboard/sidebar'
import Spinner from '../../components/spinner'

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import 'leaflet/dist/leaflet.css'
import 'react-quill/dist/quill.snow.css'
import './lawyerProfile.scss'

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

let modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [
      { indent: "-1" },
      { indent: "+1" },
      { align: [] }
    ],
  ]
}

let format = 'HH:mm'

export class LawyerProfile extends Component {
  constructor() {
    super()

    this.state = {
      loading: true,
      user: jwtDecode(localStorage.getItem('token')),
      lawyerData: '',
      latitude: '',
      longitude: '',
      address: '',
      region: 'Choose Region',
      userData: '',
      name: '',
      keywords: '',
      category: 'Choose Category',
      picture: '',
      description: '',
      phoneNumber: '',
      email: '',
      linkedin: '',
      minimumPricing: '',
      maximumPricing: '',
      slots: {
        monday: { startTime: '', endTime: '', duration: '30', interval: '5' },
        tuesday: { startTime: '', endTime: '', duration: '30', interval: '5' },
        wednesday: { startTime: '', endTime: '', duration: '30', interval: '5' },
        thursday: { startTime: '', endTime: '', duration: '30', interval: '5' },
        friday: { startTime: '', endTime: '', duration: '30', interval: '5' },
        saturday: { startTime: '', endTime: '', duration: '30', interval: '5' },
        sunday: { startTime: '', endTime: '', duration: '30', interval: '5' },
      },
      booking: true,
      availableSlot: [],
      preview: false,
      autoFocus: '',
      previewActive: 'overview',
      defaultAvailableSlots: []
    }
  }

  async componentDidMount() {
    if (this.state.user.type !== 'lawyer') return window.location.href = '/dashboard'

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }

      let { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/lawyer/get`, config)

      if (data.success) this.setState({ loading: false, lawyerData: data.lawyer, userData: data.user, name: data.lawyer.name, phoneNumber: data.lawyer.phoneNumber, email: data.lawyer.email, latitude: data.lawyer.location.latitude, longitude: data.lawyer.location.longitude, address: data.lawyer.location.address, region: data.lawyer.location.region, keywords: data.lawyer.keywords, category: data.lawyer.category, picture: data.lawyer.picture, description: data.lawyer.description, linkedin: data.lawyer.linkedin, minimumPricing: data.lawyer.pricing[0], maximumPricing: data.lawyer.pricing[1], slots: data.lawyer.appointments.slots, booking: data.lawyer.booking, defaultAvailableSlots: data.lawyer.appointments.notAvailable.map((slots) => dayjs(slots)) })

    } catch (error) {
      this.setState({ loading: false })
      console.log(error)
    }
  }

  preview() {
    if (this.state.name === '') return alert('Please provide your name')
    else if (this.state.category === 'Choose Category') return alert('Please provide your category')
    else if (this.state.address === '') return alert('Please provide your address')
    else if (this.state.latitude === '') return alert('Please provide your address latitude')
    else if (this.state.longitude === '') return alert('Please provide your address longitude')
    else if (this.state.region === 'Choose Region') return alert('Please provide your region')
    else if (this.state.picture === '') return alert('Please provide your picture for listing')
    else if (this.state.description === '') return alert('Please provide a description for your listing')
    else if (this.state.phoneNumber === '') return alert('Please provide your phone number')
    else if (this.state.email === '') return alert('Please provide your email')
    else if (this.state.minimumPricing === '') return alert('Please provide a minimum pricing for your listing')
    else if (this.state.maximumPricing === '') return alert('Please provide a maximum pricing for your listing')
    else this.setState({ preview: true })
  }

  async submit() {
    this.setState({ loading: true })

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
      
      let formData = new FormData()

      formData.append('name', this.state.name)
      formData.append('category', this.state.category)
      formData.append('keywords', this.state.keywords)
      formData.append('address', this.state.address)
      formData.append('region', this.state.region)
      formData.append('latitude', this.state.latitude)
      formData.append('longitude', this.state.longitude)
      formData.append('picture', this.state.picture)
      formData.append('description', this.state.description)
      formData.append('phoneNumber', this.state.phoneNumber)
      formData.append('email', this.state.email)
      formData.append('linkedin', this.state.linkedin)
      formData.append('minimumPricing', this.state.minimumPricing)
      formData.append('maximumPricing', this.state.maximumPricing)
      formData.append('booking', this.state.booking)
      formData.append('slots', JSON.stringify(this.state.slots))
      formData.append('notAvailable', this.state.availableSlot)
      
      let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/lawyer/upload`, formData, config)

      if (data.success) {
        this.setState({ loading: false })
        window.location.href = '/dashboard/lawyer-profile'
      }
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    return (
      <div className='dashboard'>
        <div className="sidebar">
          <DashSidebar></DashSidebar>
        </div>

        {(this.state.preview) ? (
          <div className='contents'>
            {(this.state.loading) ? <Spinner></Spinner> : (
              <div className='preview'>
                <h1 className='py-4'>Review Lawyer Profile</h1>

                <div className="info"><span className='fw-bold'>Notice!</span> This is preview of listing you've submitted, please confirm or edit your submission using buttons at the end of that page.</div>

                <div className="profile p-5">
                  <h2>{this.state.name} <span className='category'>{this.state.category}</span></h2>
                  <p className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => window.location.href = '#location'}><FontAwesomeIcon icon={faLocationDot} color='#888' className='me-2'></FontAwesomeIcon> {this.state.address}</p>
                
                  <ul className='nav'>
                    <li><a href='#overview' className={this.state.previewActive === 'overview' ? 'active' : ''}>Overview</a></li>
                    <li><a href="#gallery" className={this.state.previewActive === 'gallery' ? 'active' : ''}>Gallery</a></li>
                    <li><a href="#location" className={this.state.previewActive === 'location' ? 'active' : ''}>Location</a></li>
                  </ul>

                  <section id="overview">
                    <Markup content={this.state.description} />

                    <div className="mt-4 mb-2">
                      <h5 className='mb-2'>Pricing</h5>
                      <div className="pricing-info">
                        <FontAwesomeIcon icon={faTag} size='1x' className='pricing'></FontAwesomeIcon> 
                        <span>&#2547; {this.state.minimumPricing} - &#2547; {this.state.maximumPricing}</span>
                      </div>
                    </div>

                    <div className="overview-info">
                      <div className="contact"><a href={'tel:' + this.state.phoneNumber}><FontAwesomeIcon icon={faPhone} size='1x' className='me-2'></FontAwesomeIcon> {this.state.phoneNumber}</a></div>
                      <div className="contact"><a href={'mailto:' +  this.state.email}><FontAwesomeIcon icon={faEnvelope} size='1x' className='me-2'></FontAwesomeIcon> {this.state.email}</a></div>
                    </div>

                    <div className="mt-4 mb-2 opening">
                      <h3><FontAwesomeIcon icon={faClock} size='1x' className='me-2'></FontAwesomeIcon> Opening Hours</h3>
                      <ul>
                        <li>Monday {this.state.slots.monday.startTime === '' || this.state.slots.monday.startTime === '00:00' ? <span>Closed</span> : <span>{this.state.slots.monday.startTime} - {this.state.slots.monday.endTime}</span> }</li>
                        <li>Tuesday {this.state.slots.tuesday.startTime === '' || this.state.slots.tuesday.startTime === '00:00' ? <span>Closed</span> : <span>{this.state.slots.tuesday.startTime} - {this.state.slots.tuesday.endTime}</span> }</li>
                        <li>Wednesday {this.state.slots.wednesday.startTime === '' || this.state.slots.wednesday.startTime === '00:00' ? <span>Closed</span> : <span>{this.state.slots.wednesday.startTime} - {this.state.slots.wednesday.endTime}</span> }</li>
                        <li>Thursday {this.state.slots.thursday.startTime === '' || this.state.slots.thursday.startTime === '00:00' ? <span>Closed</span> : <span>{this.state.slots.thursday.startTime} - {this.state.slots.thursday.endTime}</span> }</li>
                        <li>Friday {this.state.slots.friday.startTime === '' || this.state.slots.friday.startTime === '00:00' ? <span>Closed</span> : <span>{this.state.slots.friday.startTime} - {this.state.slots.friday.endTime}</span> }</li>
                        <li>Saturday {this.state.slots.saturday.startTime === '' || this.state.slots.saturday.startTime === '00:00' ? <span>Closed</span> : <span>{this.state.slots.saturday.startTime} - {this.state.slots.saturday.endTime}</span> }</li>
                        <li>Sunday {this.state.slots.sunday.startTime === '' || this.state.slots.sunday.startTime === '00:00' ? <span>Closed</span> : <span>{this.state.slots.sunday.startTime} - {this.state.slots.sunday.endTime}</span> }</li>
                      </ul>
                    </div>
                  </section>

                  <section id="gallery">
                    <h3>Gallery</h3>

                    {(this.state.picture.type) ? <img src={URL.createObjectURL(this.state.picture)} className='img-fluid my-3' alt="gallery" width="400px" /> : <img src={this.state.picture} className='img-fluid my-3' alt='gallery' width="400px" />}
                  </section>

                  <section id="location">
                    <h3>Location</h3>

                    <div className="map mb-3">
                      <MapContainer center={[this.state.latitude, this.state.longitude]} zoom={10} style={{ height: '300px' }} >
                        <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker position={[this.state.latitude, this.state.longitude]} >
                          <Popup>{this.state.address}</Popup>
                        </Marker>
                      </MapContainer>
                    </div>
                  </section>
                </div>

                <div className="info">Editing listing requires admin approval, your listing will be unpublished if you Save Changes.</div>

                <div className="mt-4 submit">
                  <button className='button' onClick={() => this.setState({ preview: false })}><FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon> Edit Listing</button>
                  <button className='button' onClick={() => this.submit()}><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Save Changes</button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="contents">
            {(this.state.loading) ? <Spinner></Spinner> : (
              <div className='my-3'>
                <h1>Add Lawyer Profile</h1>


                <form onSubmit={(event) => this.preview(event)}>
                  <div className="profile">
                    <h3><FontAwesomeIcon icon={faClipboard} size='1x' className='me-4'></FontAwesomeIcon>Basic Information</h3>

                    <div className="lawyer-form">
                      <label>Lawyer Name</label>
                      <input type="text" value={this.state.name} required onChange={(event) => this.setState({ name: event.target.value })} className="form-control mb-3" placeholder='' />

                      <div className="row">
                        <div className="col-md-6">
                          <label>Category</label>
                          <select value={this.state.category} required onChange={(event) => this.setState({ category: event.target.value })} className="form-control mb-3">
                            <option value="Choose Category">Choose Category</option>
                            <option value="ACCIDENTS & INJURIES">ACCIDENTS & INJURIES</option>
                            <option value="ADR. Arbitration & Mediation">ADR. Arbitration & Mediation</option>
                            <option value="BANKRUPTCY & DEBT">BANKRUPTCY & DEBT</option>
                            <option value="BUSINESS LAW">BUSINESS LAW</option>
                            <option value="CIVIL & HUMAN RIGHTS">CIVIL & HUMAN RIGHTS</option>
                            <option value="CONSUMER RIGHTS">CONSUMER RIGHTS</option>
                            <option value="CRIMINAL DEFENSE">CRIMINAL DEFENSE</option>
                            <option value="DISABILITY">DISABILITY</option>
                            <option value="DIVORCE & FAMILY LAW">DIVORCE & FAMILY LAW</option>
                            <option value="EMPLOYEES' RIGHTS">EMPLOYEES' RIGHTS</option>
                            <option value="ESTATE PLANNING">ESTATE PLANNING</option>
                            <option value="HEALTH CARE">HEALTH CARE</option>
                            <option value="IMMIGRATION">IMMIGRATION</option>
                            <option value="INSURANCE">INSURANCE</option>
                            <option value="INTELLECTUAL PROPERTY">INTELLECTUAL PROPERTY</option>
                            <option value="REAL ESTATE">REAL ESTATE</option>
                          </select>
                        </div>
                        <div className='col-md-6'>
                          <label>Keywords</label>
                          <input type="text" value={this.state.keywords} onChange={(event) => this.setState({ keywords: event.target.value })} className="form-control mb-3" placeholder='' />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile">
                    <h3><FontAwesomeIcon icon={faLocationDot} size='1x' className='me-4'></FontAwesomeIcon>Location</h3>

                    <div className="lawyer-form">
                      <div className="map mb-3">
                        <MapContainer center={[this.state.latitude, this.state.longitude]} zoom={10} style={{ height: '300px' }} >
                          <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                          <Marker position={[this.state.latitude, this.state.longitude]} >
                            <Popup>{this.state.address}</Popup>
                          </Marker>
                        </MapContainer>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Address</label>
                          <input type="text" value={this.state.address} required onChange={(event) => this.setState({ address: event.target.value })} className='form-control mb-3' />
                        </div>
                        <div className="col-md-6">
                          <label>Region</label>
                          <select value={this.state.region} required onChange={(event) => this.setState({ region: event.target.value })} className="form-control mb-3">
                            <option value="Choose Region">Choose Region</option>
                            <option value="Dhaka, Bangladesh">Dhaka, Bangladesh</option>
                            <option value="Chittagong, Bangladesh">Chittagong, Bangladesh</option>
                            <option value="Khulna, Bangladesh">Khulna, Bangladesh</option>
                            <option value="Rajshahi, Bangladesh">Rajshahi, Bangladesh</option>
                            <option value="Sylhet, Bangladesh">Sylhet, Bangladesh</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label>Longitude</label>
                          <input type="text" value={this.state.longitude} required onChange={(event) => this.setState({ longitude: event.target.value })} className='form-control mb-3' />
                        </div>

                        <div className="col-md-6">
                          <label>Latitude</label>
                          <input type="text" value={this.state.latitude} required onChange={(event) => this.setState({ latitude: event.target.value })} className='form-control mb-3' />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile">
                    <h3><FontAwesomeIcon icon={faImage} size='1x' className='me-4'></FontAwesomeIcon> Gallery</h3>
                    <div className="lawyer-form">
                      <input type="file" required onChange={(event) => this.setState({ picture: event.target.files[0] })} className='form-control form-control-lg mb-3' />

                      {(this.state.picture.type) ? <img src={URL.createObjectURL(this.state.picture)} className='img-fluid my-3' alt="gallery" width="400px" /> : <img src={this.state.picture} className='img-fluid my-3' alt='gallery' width="400px" />}
                      
                      <div className="info">By selecting (clicking on a photo) one of the uploaded photos you will set it as Featured Image for this listing (marked by icon with star). Drag and drop thumbnails to re-order images in gallery.</div>
                    </div>
                  </div>

                  <div className="profile">
                    <h3><FontAwesomeIcon icon={faPaste} size='1x' className='me-4'></FontAwesomeIcon> Details</h3>

                    <div className="lawyer-form">
                      <ReactQuill theme='snow' modules={modules} value={this.state.description} style={{ height: '220px', marginBottom: '70px' }} onChange={(text) => this.setState({ description: text })}></ReactQuill>
                      <div className="row">
                        <div className="col-md-4">
                          <label>Phone</label>
                          <input type="text" value={this.state.userData.phoneNumber} onChange={(event) => this.setState({ phoneNumber: event.target.value })} required className="form-control mb-3" placeholder='' />
                        </div>

                        <div className="col-md-4">
                          <label>Email</label>
                          <input type="email" value={this.state.userData.email} onChange={(event) => this.setState({ email: event.target.value })} required className="form-control mb-3" placeholder='' />
                        </div>

                        <div className="col-md-4">
                          <label>Linkedin</label>
                          <input type="text" value={this.state.linkedin} onChange={(event) => this.setState({ linkedin: event.target.value })} className="form-control mb-3" placeholder='Linkedin' />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile">
                    <h3><FontAwesomeIcon icon={faTag} size='1x' className='me-4'></FontAwesomeIcon> Pricing</h3>
                    <div className="lawyer-form">
                      <div className="info mb-3">Provide the range of your pricing, indicating both the minimum and maximum range to ensure flexibility in budget considerations.</div>

                      <div className="row">
                        <div className="col-md-6">
                          <label>Minimum price</label>
                          <input type="number" min='0' className='form-control mb-3' value={this.state.minimumPricing} onChange={(event) => this.setState({ minimumPricing: event.target.value })} placeholder='BDT' required/>
                        </div>

                        <div className="col-md-6">
                          <label>Maximum price</label>
                          <input type="number" min='0' className='form-control mb-3' value={this.state.maximumPricing} onChange={(event) => this.setState({ maximumPricing: event.target.value })} placeholder='BDT' required/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile">
                    <h3 className='booking'>
                      <div>
                        <FontAwesomeIcon icon={faCalendarCheck} size='1x' className='me-4'></FontAwesomeIcon> Booking
                      </div>
                      <div className='form-check form-switch'>
                        <input className="form-check-input" type="checkbox" checked={this.state.booking} onChange={(event) => this.setState({ booking: event.target.checked })} role="switch" id="flexSwitchCheckDefault" />
                      </div>
                    </h3>

                    {(this.state.booking) ? (
                      <div className="lawyer-form">
                        <div className="info">By turning on switch on the right, you'll enable booking feature, it will add Booking widget on your listing. You'll see more configuration settings below.</div>

                        <p className='mt-4 text-center'>Time Slots</p>
                        <div className="slots">
                          <DaySlots 
                            day='Monday'
                            stateDay={this.state.slots.monday}
                            onTimeChange={(timeString) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                monday: {
                                  ...prevState.slots.monday,
                                  startTime: timeString[0],
                                  endTime: timeString[1]
                                }
                              },
                            }))}
                            onDurationChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                monday: {
                                  ...prevState.slots.monday,
                                  duration: value
                                }
                              }
                            }))}
                            onIntervalChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                monday: {
                                  ...prevState.slots.monday,
                                  interval: value
                                }
                              }
                            }))}
                          ></DaySlots>

                          <DaySlots 
                            day='Tuesday' 
                            stateDay={this.state.slots.tuesday}
                            onTimeChange={(timeString) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                tuesday: {
                                  ...prevState.slots.tuesday,
                                  startTime: timeString[0],
                                  endTime: timeString[1]
                                }
                              },
                            }))}
                            onDurationChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                tuesday: {
                                  ...prevState.slots.tuesday,
                                  duration: value
                                }
                              }
                            }))}
                            onIntervalChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                tuesday: {
                                  ...prevState.slots.tuesday,
                                  interval: value
                                }
                              }
                            }))}
                          ></DaySlots>

                          <DaySlots 
                            day='Wednesday'
                            stateDay={this.state.slots.wednesday}
                            onTimeChange={(timeString) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                wednesday: {
                                  ...prevState.slots.wednesday,
                                  startTime: timeString[0],
                                  endTime: timeString[1]
                                }
                              },
                            }))}
                            onDurationChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                wednesday: {
                                  ...prevState.slots.wednesday,
                                  duration: value
                                }
                              }
                            }))}
                            onIntervalChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                wednesday: {
                                  ...prevState.slots.wednesday,
                                  interval: value
                                }
                              }
                            }))}
                          ></DaySlots>

                          <DaySlots 
                            day='Thursday'
                            stateDay={this.state.slots.thursday}
                            onTimeChange={(timeString) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                thursday: {
                                  ...prevState.slots.thursday,
                                  startTime: timeString[0],
                                  endTime: timeString[1]
                                }
                              },
                            }))}
                            onDurationChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                thursday: {
                                  ...prevState.slots.thursday,
                                  duration: value
                                }
                              }
                            }))}
                            onIntervalChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                thursday: {
                                  ...prevState.slots.thursday,
                                  interval: value
                                }
                              }
                            }))}
                          ></DaySlots>

                          <DaySlots 
                            day='Friday' 
                            stateDay={this.state.slots.friday}
                            onTimeChange={(timeString) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                friday: {
                                  ...prevState.slots.friday,
                                  startTime: timeString[0],
                                  endTime: timeString[1]
                                }
                              },
                            }))}
                            onDurationChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                friday: {
                                  ...prevState.slots.friday,
                                  duration: value
                                }
                              }
                            }))}
                            onIntervalChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                friday: {
                                  ...prevState.slots.friday,
                                  interval: value
                                }
                              }
                            }))}
                          ></DaySlots>

                          <DaySlots 
                            day='Saturday' 
                            stateDay={this.state.slots.saturday}
                            onTimeChange={(timeString) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                saturday: {
                                  ...prevState.slots.saturday,
                                  startTime: timeString[0],
                                  endTime: timeString[1]
                                }
                              },
                            }))}
                            onDurationChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                saturday: {
                                  ...prevState.slots.saturday,
                                  duration: value
                                }
                              }
                            }))}
                            onIntervalChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                saturday: {
                                  ...prevState.slots.saturday,
                                  interval: value
                                }
                              }
                            }))}
                          ></DaySlots>

                          <DaySlots 
                            day='Sunday'
                            stateDay={this.state.slots.sunday}
                            onTimeChange={(timeString) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                sunday: {
                                  ...prevState.slots.sunday,
                                  startTime: timeString[0],
                                  endTime: timeString[1]
                                }
                              },
                            }))}
                            onDurationChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                sunday: {
                                  ...prevState.slots.sunday,
                                  duration: value
                                }
                              }
                            }))}
                            onIntervalChange={(value) => this.setState(prevState => ({
                              slots: {
                                ...prevState.slots,
                                sunday: {
                                  ...prevState.slots.sunday,
                                  interval: value
                                }
                              }
                            }))}
                          ></DaySlots>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {(this.state.booking) ? (
                    <div className="profile">
                      <h3><FontAwesomeIcon icon={faCalendarCheck} size='1x' className='me-4'></FontAwesomeIcon> Availability Calender</h3>

                      <div className="lawyer-form">
                        <div className="info mb-3">Click date in calendar to mark the day as unavailable.</div>
                        <div className="available">
                          <DatePicker defaultValue={this.state.defaultAvailableSlots} multiple required maxTagCount='responsive' size='large' onChange={(date, dateString) => this.setState({ availableSlot: dateString })}></DatePicker>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <button className='button' type='submit' onClick={() => this.preview()}>Submit</button>
                </form>

              </div>
            )}
          </div>
        )}


      </div>
    )
  }
}


class DaySlots extends Component {
  render() {
    return (
      <div className='days'>
        <p>{this.props.day}</p>

        <TimePicker.RangePicker defaultValue={[this.props.stateDay.startTime === '' ? false : dayjs(this.props.stateDay.startTime, format), this.props.stateDay.endTime === '' ? false : dayjs(this.props.stateDay.endTime, format) || '']} format={format} onChange={(time, timeString) => this.props.onTimeChange(timeString)}></TimePicker.RangePicker>

        <div className="mt-3 mb-2 slot">
          <div className="d-flex align-items-center g-2">
            <div className='ms-2'>Duration: </div>
            <select className='form-control' defaultValue={this.props.stateDay.duration} required onChange={(event) => this.props.onDurationChange(event.target.value)}>
              <option value='30'>30 mins</option>
              <option value='45'>45 mins</option>
              <option value='60'>1 hour</option>
              <option value='75'>1 hour 15 mins</option>
              <option value='90'>1 hour 30 mins</option>
              <option value='105'>1 hour 45 mins</option>
              <option value='120'>2 hour</option>
            </select>
          </div>
        </div>

        <div className="mt-2 mb-3 slot">
        <div className="d-flex align-items-center g-2">
            <div className='ms-2'>Interval: </div>
            <select className='form-control' defaultValue={this.props.stateDay.interval} required onChange={(event) => this.props.onIntervalChange(event.target.value)}>
              <option value='5'>5 mins</option>
              <option value='10'>10 mins</option>
              <option value='5'>15 mins</option>
              <option value='20'>20 mins</option>
              <option value='25'>25 mins</option>
              <option value='30'>30 mins</option>
            </select>
          </div>
        </div>
      </div>
    )
  }
}

export default LawyerProfile