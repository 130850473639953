import React, { Component } from "react";
import axios from "axios";

import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import Spinner from "../../components/spinner";
import Error from "../../components/error/error";

import signup from "../../assets/signup.jpg";

export class ForgotPassword extends Component {
  constructor() {
    super();

    if (localStorage.getItem("token")) window.location.href = "/dashboard";

    this.state = {
      loading: false,
      error: null,
      email: "",
    };

    this.forgotPasswordRequest = this.forgotPasswordRequest.bind(this);
  }

  async forgotPasswordRequest(event) {
    event.preventDefault();

    this.setState({ loading: true });

    try {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/forgot-password`,
        {
          email: this.state.email,
        }
      );

      if (data.success) {
        alert("Please check your email to get further assistance.");
        window.location.href = "/sign-up?method=login";
      }
    } catch (error) {
      return this.setState({
        error: "Email doesn't exist! Please check your email again.",
        loading: false,
      });
    }
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div className="signup my-5">
          <div className="container">
            {this.state.loading ? (
              <Spinner></Spinner>
            ) : (
              <div className="row d-flex align-items-center">
                <div className="col-md-6">
                  <img src={signup} alt="signup" className="img-fluid" />
                </div>

                <div className="col-md-6 mt-3">
                  {this.state.error ? (
                    <Error message={this.state.error}></Error>
                  ) : null}
                  <h4>Forgot password</h4>
                  <p>Let's try to recover your account</p>
                  <form onSubmit={this.forgotPasswordRequest}>
                    <input
                      type="email"
                      className="form-control "
                      required
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                      placeholder="Email address"
                    />

                    <button className="button" type="submit">
                      Reset password
                    </button>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default ForgotPassword;
