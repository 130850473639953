import { Component } from "react";
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf, faDesktop, faRocket } from '@fortawesome/free-solid-svg-icons'

import LegalServices from "../../components/legalservices/legalservices";
import Navbar from '../../components/navbar/navbar'
import Footer from "../../components/footer/footer";

import home1 from '../../assets/home1.png';
import trophy from '../../assets/trophy.jpg'

import "swiper/css";
import "swiper/css/pagination"
import "swiper/css/navigation"

import './home.scss'

export default class Home extends Component {
  constructor() {
    super()

    document.title = 'LegalX'
  }

  async orderPackage(type, price) {
    if (!localStorage.getItem('token')) return window.location.href = '/sign-up?method=register'
    localStorage.setItem('cart', JSON.stringify({ type, price }))
    return window.location.href = '/checkout'
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div className="home">
          <section className="landing">
            <div className="landing-overlay"></div>
            <div className="container">
              <div className="row pt-5 d-flex align-items-center">
                <div className="col-md-6">
                  <h1 className="heading">Reimagining legal assistance in Bangladesh</h1>
                  <p>When life meets law, you need the right legal support</p>
                </div>
                <div className="col-md-6">
                  <img src={home1} alt="home 1" className="img-fluid"></img>
                </div>
              </div>
            </div>
          </section>

          <section className="easy">
            <div className="container my-3">
              <div className="heading">
                <h1>Easy, Efficient, Affordable</h1>
                <h4>It only takes logging in to get a clear picture of what needs to be done. Sign up to learn more!</h4>
              </div>

              <div className="row mt-5">
                <div className="col-md-4">
                  <div className="border-cards">
                    <h6>Ask your query</h6>
                    <p>Our Live Chats are digitally proficient, affordable and there to help you with all your legal dilemmas. With Ask Your Query you will get answers from our experts who’ve been trained and expertly trained in law.</p>
                  </div>

                  <div className="cards">
                    <h6>Find A Lawyer</h6>
                    <p>Find your suitable lawyer from a comprehensive roster of expert legal professionals, with just a few clicks.</p>
                  </div>

                  <div className="cards">
                    <h6>Get Drafted</h6>
                    <p>Get Drafted offers a one-stop solution for all your contracting needs while staying compliant with the law, from our wide range of ready-made contracts and templates.</p>
                  </div>
                </div>
                <div className="col-md-8">
                  <img src="https://asklegalx.com/wp-content/uploads/2022/12/hello-miraaz-2.webp" alt="asd" className="img-fluid"/>
                </div>
              </div>

            </div>
          </section>

          <section className="slider">
            <div className="container">
              <div className="header p-4">
                <p>LegalX in Action</p>
                <h3>Legal services accessible without feeling prejudiced</h3>
              </div>

              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                loop={true}
                modules={[Pagination]}
                className="mySwiper p-4"  
              >
                <SwiperSlide><LegalServices title="Instant Chat Support" desc="have access to a live chat support service available around-the-clock to answer users' questions" color="#FF8F73"></LegalServices></SwiperSlide>
                <SwiperSlide><LegalServices title="Booking" desc="Once a user finds a lawyer in our directory that suits their needs, an appointment can be set up with that lawyer." color="rgb(0, 199, 229)"></LegalServices></SwiperSlide>
                <SwiperSlide><LegalServices title="Video Calling" desc="Video calling option allows you to interact with the lawyers through our platform to get a more precise solution." color="rgb(255, 227, 128)"></LegalServices></SwiperSlide>
                <SwiperSlide><LegalServices title="Call Center" desc="With our team of dedicated and diligent customer support, users can call right away for their legal inquiry" color="rgb(121, 242, 192)"></LegalServices></SwiperSlide>
                <SwiperSlide><LegalServices title="Bookmark" desc="In order to shortlist lawyers for future reference, users have the option to bookmark lawyer profiles, which are showcased separately." color="rgb(121, 226, 242)"></LegalServices></SwiperSlide>
                <SwiperSlide><LegalServices title="Resources" desc="Users can access various kinds of legal resources such as informative videos and contract drafting solutions." color="rgb(135, 119, 217)"></LegalServices></SwiperSlide>
              </Swiper>
            </div>
          </section>

          <section className="whylegalx mt-5">
            <div className="top">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                <path className="shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
                c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
                c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
              </svg>
            </div>
            <div className="container py-5">
              <div className="row d-flex align-items-center">
                <div className="col-md-6">
                  <p className="heading">Why LegalX?</p>
                  <p>For 4 years with utmost dedication, LegalX has been providing quality, affordable and result-oriented legal solutions to encourage our wide range of clients to approach legal help hassle-free.</p>
                  <p>Our seasoned, skilled, and committed team is practical and recognizes each client’s individual and unique needs, and is available round the clock to help them come up with custom solutions.</p>
                  <p>LegalX became the Champion in the “BASIS National ICT Awards 2020” in the “Professional Services (Legal, HR, etc)” category, which is the most prestigious award in Bangladesh’s ICT industry.</p>
                </div>
                <div className="col-md-6">
                  <img src={trophy} alt="trophy" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="bottom">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                <path className="shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
                c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
                c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
              </svg>
            </div>
          </section>

          <section className="testimonial">
            <div className="container">
              <Swiper
                navigation={true} 
                modules={[Navigation]}
                spaceBetween={100}
                loop={true}
                className="mySwiper p-4"
              >
                <SwiperSlide>
                  <div className="testi">
                    <div className="row">
                      <div className="col-md-8 content d-flex flex-column justify-content-between">
                        <div className="text">I was having some personal problems and had no idea who or where to turn for help until I came across LegalX. They assisted me with every aspect of my legal issues, and their prices are reasonable.</div>

                        <div className="designation">
                          <div className="border"></div>
                          <div className="name">Dayaan Ameen</div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <img src="https://asklegalx.com/wp-content/uploads/2022/12/Testimonial-Dayaan.jpg" alt="Dayaan Ameen" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="testi">
                    <div className="row">
                      <div className="col-md-8 content d-flex flex-column justify-content-between">
                        <div className="text">LegalX was ideal for me because I wanted to handle legal tasks without visiting a lawyer's office. The representatives I spoke with were quite knowledgeable, and their service is fantastic. I would strongly recommend LegalX.</div>

                        <div className="designation">
                          <div className="border"></div>
                          <div className="name">Ziaul Hassan</div>
                          <div className="name">Managing Director, SSL Network</div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <img src="https://asklegalx.com/wp-content/uploads/2022/12/Testimonial-Zia.jpg" alt="Dayaan Ameen" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="testi">
                    <div className="row">
                      <div className="col-md-8 content d-flex flex-column justify-content-between">
                        <div className="text">I initially got in touch with LegalX because I needed a lawyer and wanted to see if they might assist me. Since I was in legal difficulties, I urgently required assistance. I connected with a lawyer through Legalx based on my needs. I was quite pleased with LegalX's services.</div>

                        <div className="designation">
                          <div className="border"></div>
                          <div className="name">Sabira Mehrin Saba</div>
                          <div className="name">CEO, Wander Woman</div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <img src="https://asklegalx.com/wp-content/uploads/2022/12/Testimonial-Sabira.jpg" alt="Dayaan Ameen" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </section>

          <section className="plans">
            <div className="container">
              <h1>Select your plan</h1>
              <div className="row">
                <div className="col-md-4">
                  <div className="plan-card">
                    <h4><FontAwesomeIcon style={{ color: '#00C7E5' }} icon={faLeaf}></FontAwesomeIcon> Daily</h4>
                    <p>Get the support for 24 hours</p>
                    <div className="text-center mb-4">
                      <p className="currency">100 <span>BDT</span></p>
                      <button className="plan-button" onClick={() => this.orderPackage('Daily', 100)}>Get Started</button>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="plan-card bgblue">
                    <h4><FontAwesomeIcon style={{ color: '#00C7E5' }} icon={faDesktop}></FontAwesomeIcon> Weekly</h4>
                    <p>Get the support for 7 days</p>
                    <div className="text-center mb-4">
                      <p className="currency">600 <span>BDT</span></p>
                      <button className="plan-button" onClick={() => this.orderPackage('Weekly', 600)}>Get Started</button>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="plan-card">
                    <h4><FontAwesomeIcon style={{ color: '#00C7E5' }} icon={faRocket}></FontAwesomeIcon> Monthly</h4>
                    <p>Get the support for 30 days</p>
                    <div className="text-center mb-4">
                      <p className="currency">2000 <span>BDT</span></p>
                      <button className="plan-button" onClick={() => this.orderPackage('Monthly', 2000)}>Get Started</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="getstarted">
            <div className="container text-center">
              <h2 className="text-white">Get started with LegalX today</h2>
              <div className="d-flex justify-content-center mt-4">
                <input type="text" placeholder="Email"/>
                <button>Sign up - It's free</button>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}