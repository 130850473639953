import React, { Component } from 'react'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, Avatar, MessageGroup } from '@chatscope/chat-ui-kit-react'
import io from 'socket.io-client'

import DashSidebar from '../../../components/dashboard/sidebar'
import Spinner from '../../../components/spinner'

import './queries.scss'

import ChatLogo from '../../../assets/chat_logo.png'

let socket

export class ChatQueries extends Component {
  constructor() {
    super()

    this.state = {
      loading: true,
      user: jwtDecode(localStorage.getItem('token')),
      chatData: [],
      messages: [],
      socketConnected: false
    }

    if (this.state.user.package === 'Free') return window.location.href = '/packages'

    document.title = 'Dashboard - Messages'
  }

  async componentDidMount() {
    socket = io(process.env.REACT_APP_BACKEND_URL)
    socket.emit('setup', this.state.user.id)
    socket.on('connection', () => this.setState({ socketConnected: true }))

    socket.on('message received', (message) => {
      this.setState({ messages: [...this.state.messages, message] })
    })

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
  
      let { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/chat/get?type=query`, config)
      
      if (data.success) this.setState({ loading: false, messages: data.message })
      
      socket.emit('join chat', data.message[0].chatID)
    } catch (error) {
      this.setState({ loading: false })
      console.error(error)
    }
  }

  async sendMessage(text) {
    if (!text || text === '') return

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
  
      let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/chat/send?type=query`, { content: text }, config)

      if (data.success) {
        this.setState(prevState => ({ messages: [...prevState.messages, data.data] }))
        socket.emit('new message', data.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    return (
      <div className='dashboard'>
        <div className="sidebar">
          <DashSidebar></DashSidebar>
        </div>
        <div className="contents">
          <h1>Messages</h1>

          {(this.state.loading) ? <Spinner></Spinner> : (
            <div className="inbox my-3 p-4 pb-0">
              <div className="query-team">

              </div>
              <h4>LegalX Query Team</h4>
              <div className="chat">
                <MainContainer>
                  <ChatContainer style={{ borderRadius: '5px', height: '678px', padding: '20px 0' }}>
                    <MessageList>
                      {(this.state.messages.map((message, index) => (
                        <Message model={{ message: message.content, direction: this.state.user.id === message.sender ? 'outgoing' : 'incoming' }}  key={index}>
                          <Avatar src={this.state.user.id === message.sender ? this.state.user.avatar : ChatLogo}></Avatar>
                        </Message>
                      )))}
                    </MessageList>
                    <MessageInput placeholder="Type message here" onSend={(text) => this.sendMessage(text)}/>
                  </ChatContainer>
                </MainContainer>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ChatQueries