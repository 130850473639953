import { Component } from "react";
import logo from '../../assets/footer-logo.png';
import './footer.css'

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="row pt-5 mb-3 pb-3 border-bottom">
            <div className="col-xl-3 col-sm-6">
              <img src={logo} alt="logo" width="200px" className="mb-3" />
              <p>We build bridges between lay people and lawyer</p>
            </div>

            <div className="col-xl-3 col-sm-6">
              <h5>Important Links</h5>
              <ul className="nav flex-column">
                <li className="nav-item my-2">
                    <a href="/faqs" className="nav-link p-0 text-muted">FAQ</a>
                </li>
                <li className="nav-item my-2">
                    <a href="/privacy-policy" className="nav-link p-0 text-muted">Privacy Policy</a>
                </li>
                <li className="nav-item my-2">
                    <a href="/terms-conditions" className="nav-link p-0 text-muted">Terms & Conditions</a>
                </li>
                <li className="nav-item my-2">
                    <a href="/refund-return-policy" className="nav-link p-0 text-muted">Refund & Return Policy</a>
                </li>
                <li className="nav-item my-2">
                    <a href="/blogs" className="nav-link p-0 text-muted">Blog</a>
                </li>
              </ul>
            </div>

            <div className="col-xl-3 col-sm-6">
              <h5>Jobs</h5>
              <p>Learn more about open roles on the LegalX Partners Team.</p>
            </div>

            <div className="col-xl-3 col-sm-6">
              <h5>Contact Us</h5>
              <p>Need anything? Get in touch and we can help.</p>
            </div>

          </div>

          <div className="text-center py-3">
            <p>Copyright &copy; {new Date().getFullYear()} LegalX</p>
          </div>
        </div>
      </div>
    )
  }
}